import React from "react";
import { Link } from "gatsby";

import { useStyletron } from "baseui";

import { Grid, Cell } from "baseui/layout-grid";
import { Block } from "baseui/block";

import { Heading } from "baseui/heading";
import { Paragraph1 } from "baseui/typography";

import { Button, KIND, SIZE } from "baseui/button";
import { StatefulTooltip } from "baseui/tooltip";

import FrpImage from "../images/percent.svg";

import { SMALL } from "../themes/media-queries";

const Artboard = () => {
  const [css, theme] = useStyletron();

  const space = css({ marginLeft: theme.sizing.scale800 });

  const tooltipStyles = css({
    color: theme.colors.primary,
    borderBottom: `3px dotted ${theme.colors.primary}`,
    cursor: "help",
    ":hover": {
      color: theme.colors.primary500,
    },
    ":focus": {
      outline: `3px solid ${theme.colors.accent}`,
    },
    ":active": {
      outline: `none`,
    },
  });

  return (
    <Block
      className={css({
        padding: "50px 0",
      })}
    >
      <Grid>
        <Cell span={[4, 6, 6]}>
          <Heading>
            Гранты до{" "}
            <StatefulTooltip
              content={() => (
                <p padding={"20px"}>
                  До 90% затрат на уплату процентов по кредитам
                </p>
              )}
              showArrow
              accessibilityType={"tooltip"}
              focusLock={false}
            >
              <span tabIndex="0" className={tooltipStyles}>
                {(50).toLocaleString("ru-RU")}&nbsp;млн&nbsp;&#8381;
              </span>
            </StatefulTooltip>{" "}
            на компенсацию процентов по кредитным договорам
          </Heading>
          <Paragraph1 margin="0">
            Доступ промышленных предприятий области к&nbsp;недорогим кредитам
          </Paragraph1>
          <Block
            className={css({
              marginBottom: theme.sizing.scale800,
              [SMALL]: {
                textAlign: "center",
              },
            })}
          >
            <Button
              className={css({ marginTop: theme.sizing.scale800 })}
              $as={Link}
              to="/frp/apply-for-grant"
              kind={KIND.primary}
              size={SIZE.large}
            >
              Подать заявку
            </Button>
            <span className={space} />
            <Button
              className={css({ marginTop: theme.sizing.scale400 })}
              $as={Link}
              to="/frp/grants"
              kind={KIND.tertiary}
              size={SIZE.large}
            >
              Подробнее о грантах
            </Button>
          </Block>
        </Cell>
        <Cell span={[0, 0, 6]}>
          <FrpImage
            className={css({
              maxHeight: "315px",
              width: "100%",
            })}
          />
        </Cell>
      </Grid>
    </Block>
  );
};

export default Artboard;

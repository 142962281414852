import React, { useEffect, useState } from "react";
import { graphql, Link } from "gatsby";

import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Grid, Cell } from "baseui/layout-grid";
import { HeadingLevel, Heading } from "baseui/heading";
import { Button, KIND } from "baseui/button";
import { ChevronRight } from "baseui/icon";
import { ProgressBar, SIZE } from "baseui/progress-bar";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Artboard from "../../components/artboard-frp";
import GrantsArtboard from "../../components/artboard-frp-grants";
import News from "../../components/news";
import NewsCard from "../../components/newsCard";
import CmfLogo from "../../components/logo";
import MspLogo from "../../components/logo-msp";

import theme from "../../themes/frp";

const Frp = ({ data }) => {
  const [css] = useStyletron();
  const [isSwitched, setSwitch] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const coundown = setInterval(() => {
      setProgress((t) => {
        if (t === 100) {
          setSwitch((bool) => !bool);
          return 0;
        } else {
          return t + 20;
        }
      });
    }, 1000);

    return () => {
      clearInterval(coundown);
    };
  }, []);

  return (
    <Layout theme="frp">
      <SEO title="Фонд развития промышленности Архангельской области" />
      <HeadingLevel>
        {isSwitched ? <Artboard /> : <GrantsArtboard />}
        <ProgressBar
          value={progress}
          size={SIZE.small}
          overrides={{
            BarProgress: {
              style: () => ({
                backgroundColor: theme.colors.primary,
              }),
            },
          }}
        />
        <Block
          className={css({
            backgroundColor: theme.colors.mono200,
            padding: "50px 0",
          })}
        >
          <Grid>
            <Cell span={[4, 6, 8]}>
              <News data={data.allMarkdownRemark} />
              <Button
                $as={Link}
                to="/news"
                endEnhancer={<ChevronRight size={24} />}
                kind={KIND.tertiary}
              >
                Все новости
              </Button>
            </Cell>
            <Cell span={[4, 6, 4]}>
              <HeadingLevel>
                <Heading>Информация</Heading>
                <NewsCard
                  date="Консультации регионального фонда"
                  title="(8182) 46-41-22 (доб. 107)"
                  description='Консультации проводит финансовый аналитик Фонда "МКК Развитие" Иванова Полина Сергеевна –&nbsp;frp@cmf29.ru'
                  link="/frp/about"
                />
                <NewsCard
                  title="8 800 500-71-29"
                  date="Федеральный консультационный центр"
                  description="Консультации по мерам поддержки промышленных предприятий на сайте федерального Фонда развития промышленности"
                  link="https://frprf.ru/navigator-gospodderzhky/"
                />
              </HeadingLevel>
            </Cell>
          </Grid>
        </Block>
        <Grid>
          <Cell span={12}>
            <Block
              className={css({
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "space-around",
              })}
            >
              <Block
                className={css({
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                })}
              >
                <CmfLogo />
              </Block>
              <Block
                className={css({
                  display: "flex",
                  flexWrap: "nowrap",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "20px",
                  paddingRight: "20px",
                })}
              >
                <MspLogo />
              </Block>
            </Block>
          </Cell>
        </Grid>
      </HeadingLevel>
    </Layout>
  );
};

export default Frp;

export const query = graphql`
  {
    allMarkdownRemark(
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tag: { eq: "ФРП" } } }
    ) {
      nodes {
        frontmatter {
          title
          date(formatString: "DD MMMM YYYY", locale: "ru")
          tag
        }
        excerpt
        id
        fields {
          slug
        }
      }
    }
  }
`;
